import React from 'react';
import './introSection.css';

import Grid from '@material-ui/core/Grid';

import TypingIntro from '../../Components/typing/typing';
import Particles from '../../Components/particles/particles';
// import Banner from "../../assets/img/banner.svg";

export default function IntoSection() {
	return (
		<div id='main1'>
			<Particles />
			<Grid
				container
				alignItems='center'
				justify='center'
				style={{ zIndex: 12 }}
			>
				{/* <Grid item xs={12} sm={12} md={7}>
					<div className="introImgDiv">
						<img src={Banner} alt="intro-banner" className="introImg" />
					</div>
				</Grid> */}
				<Grid item xs={12} sm={12} md={5}>
					<div className='typingDiv'>
						<TypingIntro />
					</div>
				</Grid>
			</Grid>
		</div>
	);
}
