import React from "react";
// import { createMuiTheme, ThemeProvider } from '@material-ui/core';

import "./App.css";
import Home from "./Screens/Home";

// const THEME = createMuiTheme({
//   typography: {
//     "fontFamily": `"courier"`,
//     "fontSize": 14,
//     "fontWeightLight": 300,
//     "fontWeightRegular": 400,
//     "fontWeightMedium": 500
//   }
// });

function App() {
  // <ThemeProvider theme={THEME}>
  // {/* </ThemeProvider> */}
  return (
    <div className="App">
      <Home />
    </div>
  );
}

export default App;
