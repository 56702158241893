import React, { useRef } from "react";
import AppBar from "../Components/appBar";

import IntroSection from "../Views/Home/IntroSection";
import PlatformSection from "../Views/Home/platformSection";
import QualitySection from "../Views/Home/qualitySection";
import MyServiceSection2 from "../Views/Home/serviceSection";
import AboutUs from "../Views/Home/aboutUs";
import LocationSection from "../Views/Home/locationSection";
import ContactUs from "../Views/Home/contactUs";
import FooterSection from "../Views/Home/footerSection";
import TestimonialsSection from "../Views/Home/testimonialsSection";

import "./home.css";

export default function Home() {
  const homeRef = useRef(null);
  const aboutRef = useRef(null);
  const servicesRef = useRef(null);
  const platformsRef = useRef(null);
  const qualityRef = useRef(null);
  const testimonialsRef = useRef(null);
  const locationsRef = useRef(null);
  const contactRef = useRef(null);

  return (
    <div className="homeMain">
      <AppBar
        data={{
          homeRef,
          aboutRef,
          servicesRef,
          platformsRef,
          qualityRef,
          testimonialsRef,
          locationsRef,
          contactRef,
        }}
      />
      <br />
      <br />
      <br ref={homeRef} />
      <div>
        <IntroSection />
      </div>
      <div ref={aboutRef}>
        <AboutUs />
      </div>
      <div ref={servicesRef}>
        <MyServiceSection2 />
      </div>
      <div ref={platformsRef}>
        <PlatformSection />
      </div>
      <div ref={qualityRef}>
        <QualitySection />
      </div>
      <div ref={testimonialsRef}>
        <TestimonialsSection />
      </div>
      <div ref={locationsRef}>
        <LocationSection />
      </div>
      <div ref={contactRef}>
        <ContactUs />
      </div>
      <div>
        <FooterSection />
      </div>
    </div>
  );
}
