import React from "react";
import { useTranslation } from "react-i18next";

import Grid from "@material-ui/core/Grid";

import "../Global/style.css";
import "./aboutUs.css";
import "./aboutUs.css";

// import logo from "../../assets/extra/aboutUs.png";
import logo from "../../assets/img/creative-team.svg";

export default () => {
  const { t } = useTranslation();
  return (
    <div id="main2">
      <div className="globalOverlayHeading aboutOverlayHeading">
        <p>{t("who_we_are")}</p>
      </div>
      <div>
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <div className="aboutTopHeadingMain">
              <div className="aboutTopHeading">
                <p>
                  <b>{t("we_develop_ideas")}</b>
                </p>
              </div>
              <div className="aboutUsHeadingBorder" />
              <div className="aboutTopSubHeading">
                <p>{t("changing_the_world")}</p>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6} className="aboutUsBannerDiv">
            <img src={logo} alt="logo" className="aboutUsBannerImg" />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
