import React from "react";
import { withTranslation } from "react-i18next";

import "./platformSection.css";

import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

import { MdWeb, MdAndroid, MdLaptopMac } from "react-icons/md";
import { FaApple, FaWindows, FaLinux } from "react-icons/fa";

function platformSection(props) {
  const { t } = props;

  const platforms = [
    {
      Icon: <MdWeb size="3em" />,
      name: "Website",
      desc: t("ecommerce"),
    },
    {
      Icon: <MdAndroid size="3em" />,
      name: "Android",
      desc: t("android_apps"),
    },
    {
      Icon: <FaApple size="3em" />,
      name: "iOS",
      desc: t("we_make_it"),
    },
    {
      Icon: <MdLaptopMac size="3em" />,
      name: "OSX",
      desc: t("desktop_apps"),
    },
    {
      Icon: <FaWindows size="3em" />,
      name: "Windows",
      desc: t("forget_the_conventional"),
    },
    {
      Icon: <FaLinux size="3em" />,
      name: "Linux",
      desc: t("get_your_desktop_app"),
    },
  ];

  return (
    <div className="main4">
      <Grid container>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className="platformTopDiv">
            <Typography className="platformHeading">
              {t("platforms_we_work")}
            </Typography>
            <div className="platformHeadingBorder" />
          </div>
        </Grid>
      </Grid>
      <Grid container justify="center" className="platformTypeDiv">
        {platforms.map(({ Icon, name, desc }, index) => {
          return (
            <Grid
              item
              key={index}
              xs={6}
              sm={4}
              md={2}
              className="platformType flip-card"
            >
              <div className="flip-card">
                <div className="flip-card-inner">
                  <div className="flip-card-front platformCard">
                    {Icon}
                    <Typography className="platformName">{name}</Typography>
                  </div>
                  <div className="flip-card-back">
                    <h1>{name}</h1>
                    <p className="platformDesc">{desc}</p>
                  </div>
                </div>
              </div>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}

export default withTranslation()(platformSection);
