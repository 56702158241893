import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { fade, makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TranslateIcon from "@material-ui/icons/Translate";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InputBase from "@material-ui/core/InputBase";
// import SearchIcon from "@material-ui/icons/Search";
// import Divider from "@material-ui/core/Divider";

import HomeRoundedIcon from "@material-ui/icons/HomeRounded";
import ViewComfyRoundedIcon from "@material-ui/icons/ViewComfyRounded";
import DevicesRoundedIcon from "@material-ui/icons/DevicesRounded";
import ContactMailRoundedIcon from "@material-ui/icons/ContactMailRounded";
// import InfoRoundedIcon from "@material-ui/icons/InfoRounded";
// import CameraEnhanceRoundedIcon from "@material-ui/icons/CameraEnhanceRounded";
// import ClearAllRoundedIcon from "@material-ui/icons/ClearAllRounded";
// import PinDropRoundedIcon from "@material-ui/icons/PinDropRounded";

import "./appBar.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const languages = [
  { title: "English", code: "en" },
  { title: "Française", code: "fr" },
];

export default function (props) {
  const {
    homeRef,

    servicesRef,
    platformsRef,
    contactRef,
    // qualityRef,
    // testimonialsRef,
    // locationsRef,
  } = props.data;

  const classes = useStyles();
  const { i18n } = useTranslation();

  const [anchorEl, setAnchorEl] = useState(null);
  const [language, setLanguage] = useState("English");
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    // showHideAppBar();
    const localLanguage = localStorage.getItem("i18nextLng");

    switch (localLanguage) {
      case "en": {
        setLanguage("English");
        break;
      }
      case "fr": {
        setLanguage("Française");
        break;
      }
      default:
        break;
    }
  }, []);

  // const showHideAppBar = () => {
  //   var prevScrollpos = window.pageYOffset;
  //   window.onscroll = function () {
  //     var currentScrollPos = window.pageYOffset;
  //     if (prevScrollpos > currentScrollPos) {
  //       document.getElementById("navbar").style.top = "0";
  //     } else {
  //       document.getElementById("navbar").style.top = "-75px";
  //     }
  //     prevScrollpos = currentScrollPos;
  //   };
  // };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeLanguage = ({ title, code }) => {
    i18n.changeLanguage(code);
    setAnchorEl(null);
    setLanguage(title);
  };

  const scrollToRef = (ref) => window.scrollTo(0, ref?.current?.offsetTop);

  const toggleDrawer = (bool) => setIsOpen(bool);

  const sideList = () => {
    // homeRef, aboutRef, servicesRef, platformsRef, testimonialsRef, locationsRef, contactRef
    const obj = (sectionRef, title, icon) => ({ sectionRef, title, icon });
    const ITEMS = [
      obj(homeRef, "Home", <HomeRoundedIcon />),
      obj(servicesRef, "Services", <ViewComfyRoundedIcon />),
      obj(platformsRef, "Platforms", <DevicesRoundedIcon />),
      obj(contactRef, "Contact Us", <ContactMailRoundedIcon />),
    ];
    // <div
    //     style={{
    //         width: "250px",
    //         height: "200px",
    //         background: 'linear-gradient(90deg, #1488cc 0%,#2b32b2 100% )',
    //     }}
    // >
    //     {/* {user && ( */}
    //     <span>
    //         {/* <Avatar
    //         src={require("./../../Assets/Images/profile1.png")}
    //         className={classes.avatar}
    //         alt="Profile Picture"
    //     /> */}
    //         <br />
    //         <br />
    //         <br />
    //         {/* <Typography className={classes.drawerText} variant="overline">
    //         {"user.displayName"}
    //     </Typography>
    //     <Typography className={classes.drawerText} variant="body2">
    //         {"user.email"}
    //     </Typography> */}
    //     </span>
    //     {/* )} */}
    // </div>
    // <Divider />
    return (
      <div style={{ width: "250px" }}>
        <List>
          {ITEMS.map((item, index) => {
            return (
              <div key={index} onClick={() => scrollToRef(item.sectionRef)}>
                <ListItem button>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.title} />
                </ListItem>
              </div>
            );
          })}
        </List>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar
        id="navbar"
        position="fixed"
        color="default"
        style={{ backgroundColor: "#fff" }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            className="hamburgerIcon"
            color="inherit"
            aria-label="open drawer"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            color="primary"
          >
            StackBeans
          </Typography>
          {/* <div className={classes.search}>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            placeholder="Search…"
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            inputProps={{ "aria-label": "search" }}
                        />
                    </div> */}
          <div className="menuBtn">
            <Button color="primary" onClick={() => scrollToRef(homeRef)}>
              Home
            </Button>
            <Button color="primary" onClick={() => scrollToRef(servicesRef)}>
              Services
            </Button>
            <Button color="primary" onClick={() => scrollToRef(platformsRef)}>
              Platforms
            </Button>
            <Button color="primary" onClick={() => scrollToRef(contactRef)}>
              Contact Us
            </Button>
          </div>
          <div>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              color="primary"
              onClick={handleClick}
            >
              <TranslateIcon color="primary" style={{ marginRight: 10 }} />
              {language}
              <ExpandMoreIcon color="primary" style={{ marginLeft: 5 }} />
            </Button>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              {languages.map((language, index) => (
                <MenuItem
                  key={language.title}
                  onClick={() => changeLanguage(language)}
                >
                  {language.title}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <SwipeableDrawer
            anchor="left"
            open={isOpen}
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <div
              tabIndex={0}
              role="button"
              onClick={() => toggleDrawer(false)}
              onKeyDown={() => toggleDrawer(false)}
            >
              {sideList()}
            </div>
          </SwipeableDrawer>
        </Toolbar>
      </AppBar>
    </div>
  );
}
