let fr = {
  translations: {
    intro_slogan: "Les idées innovantes nécessitent des solutions modernes",
    who_we_are: "QUI NOUS SOMMES",
    we_develop_ideas:
      "Nous Développer des idées, infuser dans les produits latents et lancer",
    need_solution: "besoin d'une solution pour votre idée?",
    changing_the_world:
      "StackBeans change à son tour le monde dans lequel nous vivons à chaque étape. Nous adoptons une stratégie axée sur l'avancement pour aider à envisager et à repenser le sort éventuel des entreprises. Notre portefeuille traverse des segments clés de la solidarité, y compris les applications mobiles (IOS et Android), Web et de bureau (Mac, Windows, Linux)",
    what_we_do: "CE QUE NOUS FAISONS",
    unique_hybrid: "Solutions hybrides uniques",
    we_focus_on:
      "Nous nous concentrons sur la création de solutions numériques innovantes en fonction des besoins de nos clients.",
    uiux_design: "Conception et développement UI/UX",
    designing: "Conception",
    beyond_pretty:
      "Allez au-delà du joli. Nous réalisons qu'un bon plan est une entreprise décente et nous rendons votre marque extraordinaire en créant une UI/UX interactive et unique en utilisant les meilleures compétences, les meilleurs outils et les meilleures approches.transformez votre idée en prototype et en UI/UX super attrayants pour augmenter votre productivité et votre expérience utilisateur. Essayez-nous",
    lets_talk: "PARLONS",
    mobile_app_development: "Développement d'applications mobiles",
    cross_platform: "Multiplateforme",
    provide_first_class:
      "StackBeans fournit le développement d'applications mobiles de première classe sur le marché aux meilleurs prix. Nous y parvenons en conservant les meilleurs tests, des planificateurs imaginatifs et un groupe indéniable pour assembler un format d'applications mobiles évolué et intéressant. nous développons des applications mobiles multiplateformes pour Android et iOS et Windows Phone",
    desktop_app_development: "Développement d'applications de bureau",
    uses_modern_technology:
      "StackBeans utilise une technologie moderne pour créer une application de bureau puissante et pourtant de petite taille qui peut s'exécuter sur plusieurs plates-formes à partir d'une seule base de code avec une faible consommation de mémoire sur l'appareil des utilisateurs. Que vous souhaitiez une application de bureau hors ligne ou en ligne. Une puissante application de bureau développée par ElectronJS pour Windows, OSX, Linux",
    website_design: "Conception et développement de sites Web",
    web: "la toile",
    we_have_practical:
      "Nous avons une expérience pratique dans reactJs, javascript, jquery, HTML, CSS avec PWA pour créer des applications web intelligentes et merveilleuses.Avec un format extérieurement convaincant et une ingénierie actuelle pour créer un actif numérique parfait pour votre entreprise.Développement et conception de sites Web personnalisés adaptés aux mobiles et réactifs",
    graphic_design: "Conception graphique et prototype",
    give_your_design:
      "Donnez à votre design une nouvelle sensation et vie. Nous réalisons des interfaces et des graphiques entièrement personnalisés dans le but final que les écrans et les applications Web Le format couvrira toutes les parties de la structure qui améliorent la commodité, les logos professionnels, les dépliants, les bannières, les publicités et les icônes pour vos applications et vos promotions commerciales. 🔥🔥",
    website_and_app: "Gestion de sites Web et d'applications",
    maintainance: "Maintien",
    we_will_make_sure:
      "Nous veillerons à ce que vous obteniez les meilleures performances après la phase de production et nous vous fournirons une excellente gestion de votre site Web et de vos applications même après la production de votre application.Nous surveillons votre Web et votre appli 24/7 afin que vous ne soyez pas pris de panique lorsque votre web et votre appli seront accessibles à des millions d'utilisateurs",

    ///////////////////////////////////////////////

    ///////////////////////////////////////////////

    platforms_we_work: "plateformes sur lesquelles nous travaillons",
    ecommerce:
      "Commerce électronique, applications à page unique et à pages multiples, applications Web progressives (PWA)",
    android_apps:
      "Applications Android pour les start-ups et les entrepreneurs avec une interface utilisateur et des animations parfaites",
    we_make_it:
      "Nous vous facilitons l'accès au monde IOS avec un code et une mémoire optimisés",
    desktop_apps:
      "Applications de bureau pour votre OSX avec toutes les fonctionnalités pratiques",
    forget_the_conventional:
      "Oubliez la façon conventionnelle de développer les applications mobiles et de bureau de Windows",
    get_your_desktop_app:
      "Obtenez vos applications de bureau sur la plate-forme Linux avec So Ease",

    ///////////////////////////////////////////////
    // QUALITY SECTION
    ///////////////////////////////////////////////

    why_choose: "pourquoi choisir",
    clean_code: "code propre",
    developers_take_good:
      "Les développeurs StackBeans prennent bien soin des programmes qu'ils écrivent. Nous le rendons simple et ordonné pour le développement futur de votre projet. Nous le faisons en suivant les conventions de programmation, en documentant, en écrivant du code dans un format facile à lire avec des noms de variables et de fonctions compréhensibles. À cette époque où de nombreuses personnes naviguent sur le Web à l'aide de petits appareils, StackBeans doit s'assurer que les sites Web peuvent être consultés sur pratiquement n'importe quel écran, de sorte que nous intégrons tout le soutien supplémentaire nécessaire pour prendre en charge quoi que ce soit, des téléphones mobiles aux grands écrans.",
    agile_approach: "approche agile",
    plan_for_how:
      "StackBeans planifie comment nous pouvons améliorer les choses pour les personnes qui utilisent votre contenu numérique. Notre vision prend la forme d'une feuille de route. Notre approche est une méthode de travail basée sur des principes qui permet aux concepteurs, développeurs, éditeurs et experts en la matière de réellement collaborer au sein d'équipes multidisciplinaires. DESIGN THINKING est notre conviction, LEAN est notre foi et AGILE est notre confiance.",
    deep_code: "analyse de code approfondie",
    make_sure_that:
      "StackBeans s'assure que votre code est exempt de bogues et de rouille. Chaque phase de développement passe par une série de tests pour s'assurer qu'aucune partie de la couche d'application n'est manquée. Chaque vulnérabilité manquée augmente le risque de pannes importantes à l'échelle du système, diminue la sécurité et augmente le budget informatique. StackBeans s'assure que les applications développées dans notre organisation n'entraînent pas de problèmes de sécurité, d'échec de conformité ou de tout autre problème.",
    scalability: "évolutivité",
    in_order_to_ensure:
      "Afin de garantir que votre logiciel sera utilisable quel que soit le nombre de clients que vous avez, StackBeans écrit du code de manière à garantir une évolutivité maximale. Tout sera écrit en pensant à l'avenir, sauf demande contraire de votre part. Cela peut coûter plus cher à court terme, mais cela coûte beaucoup moins à long terme, car les logiciels non évolutifs doivent être révisés ou remplacés entièrement pour répondre à la demande croissante et aux changements d'architecture.",
    robust_testing: "tests robustes",
    during_each_phase:
      "Lors de chaque phase de développement de votre logiciel, nous effectuons des tests pour identifier les failles de toutes sortes afin de nous assurer que nous vous donnons les résultats optimaux que vous désirez et méritez. À la fin du développement, des tests supplémentaires sont effectués et, si vous en avez besoin, des tests utilisateur peuvent être effectués avant de publier le logiciel pour les utilisateurs finaux généraux. Pourquoi s'inquiéter du risque d'accident lorsque vous pouvez l'éviter?",
    code_appraisal: "évaluation du code",
    break_down_your_app:
      "StackBeans décompose votre application en petites parties encapsulées qui peuvent ensuite être composées pour rendre plus complexe, une interface utilisateur robuste et appliquer une logique commerciale à chaque composant afin qu'il devienne indolore pour nos développeurs et nos chefs d'équipe dans l'évaluation des exigences.",

    ///////////////////////////////////////////////
    // LOCATION SECTION
    ///////////////////////////////////////////////

    where_we_are: "où nous sommes",
    operating_in: "Fonctionnant dans 3 régions et cultures différentes",
    the_plan_of:
      "le plan d'avoir une équipe de travail à des milliers de kilomètres pourrait être intéressant, qu'en est-il lorsque nous essayons de gérer une entreprise avec eux? nous croyons en la fourniture de nos services non seulement à l'intérieur des frontières de l'État local, mais également aux clients internationaux",

    ///////////////////////////////////////////////
    // CONTACTUS SECTION
    ///////////////////////////////////////////////

    name: "Nom",
    email: "email",
    phone: "téléphone",
    company_name: "Nom de la compagnie",
    website_development: "développement site web",
    mobile_development: "développement mobile",
    desktop_application: "application de bureau",
    design: "conception",
    branding: "l'image de marque",
    other: "autre",
    your_smart:
      "votre endroit intelligent pour convertir vos idées en état de marche. Cela peut être n'importe quoi: un site Web, une application mobile (IOS et Android), une application de bureau (OSX, Windows, Linux). Votre idée est notre objectif pour y parvenir 😊",
    lets_get_started: "Commençons",
    services: "prestations de service",
    estimated_budget: "Budget estimé",
    submit: "soumettre",
    location: "Emplacement",
    contact: "Contact",
    we_would_like: "Nous aimerions en savoir plus",

    ///////////////////////////////////////////////
    // FOOTER SECTION
    ///////////////////////////////////////////////

    all_rights: "Tous les droits sont réservés",
  },
};

export default fr;
