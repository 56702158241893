import React from "react";
import { withTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";

import "../Global/style.css";
import "./testimonialsSection.css";

const DATA = [
  {
    id: 1,
    name: "Muhammad Ali Mughal",
    position: "Manager - SMIT & PIAIC",
    image: require("../../assets/testimonials/ali-mughal.jpg"),
    review:
      "My experience with this team is always a remember-able proposition , the professionalism they showed everytime is their key of their triumph , may they get progress in all of their serving areas",
  },
  {
    id: 2,
    name: "Muhammad Kashif Sulaiman",
    position: "Founder - Expertizo",
    image: require("../../assets/testimonials/kashif-sulaiman.jpg"),
    review:
      "The Way SB serving the tech community by putting up their efforts to connect the experts & amateur to the willing ones is amazing, may they keep their efforts headed in a right direction",
  },
  {
    id: 1,
    name: "Haider Ali",
    position: "Founder - Stack Learners",
    image: require("../../assets/testimonials/haider-ali.jpg"),
    review:
      "Team is a very skilled and knowledgeable professional. SB has a broader view of technologies that not get stopped by anything. The way these people puts attention in their work is really commendable",
  },
];
const TestimonialsSection = ({ t, ...props }) => {
  const _renderTestimonials = () => {
    return DATA.map((item) => {
      return (
        <div>
          <Paper className="item" elevation={20}>
            <Typography variant="subtitle2" className="clientReview">
              "{item.review}"
            </Typography>
            <Grid
              container
              // xs={12}
              direction="row"
              justify="center"
              alignItems="center"
              className="profile"
            >
              <Grid item xs={3}>
                <Avatar src={item.image} alt={item.name} />
              </Grid>
              <Grid item xs={9}>
                <Typography className="clientName">{item.name}</Typography>
                <Typography variant="overline" className="clientPosition">
                  {item.position}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </div>
      );
    });
  };

  return (
    <div id="testimonialContainer">
      <div className="globalOverlayHeading serviceOverlayHeading">
        <p>What They Think</p>
      </div>
      <div className="horizontalScrollWrapper">      
        {_renderTestimonials()}
      </div>
    </div>
  );
};

export default withTranslation()(TestimonialsSection);
