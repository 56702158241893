import { firebase } from './firebase';

const scrollToElement = (id) => {
	document.getElementById(id).scrollIntoView({
		behavior: 'smooth',
	});
};

const getFirebaseTimestamp = () => firebase.firestore.Timestamp.now().toMillis();

export { scrollToElement, getFirebaseTimestamp };
