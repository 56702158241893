import firebase from 'firebase/app';

import 'firebase/analytics';
import 'firebase/firestore';

// TODO: Replace the following with your app's Firebase project configuration
// For Firebase JavaScript SDK v7.20.0 and later, `measurementId` is an optional field
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: 'AIzaSyBxXpYnaEO3j9QTH4NdE4QLnV8mTfjz4zE',
	authDomain: 'stackbeans-pk.firebaseapp.com',
	databaseURL: 'https://stackbeans-pk.firebaseio.com',
	projectId: 'stackbeans-pk',
	storageBucket: 'stackbeans-pk.appspot.com',
	messagingSenderId: '611559805471',
	appId: '1:611559805471:web:aa58551763db3f94d57b2a',
	measurementId: 'G-D0CX0P4RVE',
};

firebase.initializeApp(firebaseConfig);

firebase.analytics().setAnalyticsCollectionEnabled(true);

const DB = firebase.firestore();

export { DB, firebase };
