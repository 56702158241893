import React from "react";
import { withTranslation } from "react-i18next";

import "./footerSection.css";
import globalData from "../../config/globaldata";

const FooterSection = (props) => {
  const { t } = props;
  return (
    <div id="footer">
      <div className="footerMainDiv">
        <p>
          &copy; {globalData.date} <b>{globalData.name}</b> | {t("all_rights")}
        </p>
      </div>
    </div>
  );
};

export default withTranslation()(FooterSection);
