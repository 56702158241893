import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import './qualitySection.css';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';

import globalData from '../../config/globaldata';

import testingLogo from '../../assets/extra/testing.png';
import analysisLogo from '../../assets/extra/analysis.png';
import appraisalLogo from '../../assets/extra/appraisal.png';
import codingLogo from '../../assets/extra/coding.png';
import scalabilityLogo from '../../assets/extra/scalability.png';
import agileLogo from '../../assets/extra/agile.png';

let refs = [];
function QualitySection(props) {
	const { t } = props;

	const [currentIndex, setCurrentIndex] = useState(0);
	const [lastIndex, setLastIndex] = useState(null);

	const data = [
		{
			logo: codingLogo,
			alt: 'code',
			name: t('clean_code'),
			desc: t('developers_take_good'),
		},
		{
			logo: agileLogo,
			alt: 'agile',
			name: t('agile_approach'),
			desc: t('plan_for_how'),
		},
		{
			logo: analysisLogo,
			alt: 'analysis',
			name: t('deep_code'),
			desc: t('make_sure_that'),
		},
		{
			logo: scalabilityLogo,
			alt: 'scalability',
			name: t('scalability'),
			desc: t('in_order_to_ensure'),
		},
		{
			logo: testingLogo,
			alt: 'testing',
			name: t('robust_testing'),
			desc: t('during_each_phase'),
		},
		{
			logo: appraisalLogo,
			alt: 'appraisal',
			name: t('code_appraisal'),
			desc: t('break_down_your_app'),
		},
	];

	useEffect(() => {
		if (lastIndex === null) refs[currentIndex].classList.toggle('shadowActive');
		else {
			refs[currentIndex].classList.toggle('shadowActive');
			refs[lastIndex].classList.toggle('shadowActive');
		}
	}, [currentIndex, lastIndex, refs]);

	const setIndexValue = (index, e) => {
		setLastIndex(currentIndex);
		setCurrentIndex(index);
		// console.log(e.target.classList.toggle("shadowActive"));
	};

	return (
		<div id='main5'>
			<Grid container>
				<Grid item xs={12} sm={12} md={12} lg={12}>
					<div className='qualityTopDiv'>
						<Typography className='qualityHeading'>
							{t('why_choose')} <b>{`<${globalData.name}/>`}</b> ?
						</Typography>
					</div>
				</Grid>
			</Grid>
			<Grid container justify='center' className='qualityContainer'>
				{data.map((value, i) => {
					return (
						<Grid
							key={i}
							item
							xs={6}
							sm={4}
							md={4}
							lg={4}
							className={'qualityType'}
							onClick={(e) => setIndexValue(i, e)}
						>
							<Card
								ref={(ref) => refs.push(ref)}
								className='qualityCardDiv shadowActive'
							>
								<div>
									<img
										src={value.logo}
										alt={value.alt}
										className='qualityTypeLogo'
									/>
								</div>
								<Typography className='qualityTypeName'>
									{value.name}
								</Typography>
							</Card>
						</Grid>
					);
				})}
			</Grid>
			<div className='qualityDescDiv'>
				{
					<Typography className='qualityTypeDesc'>
						{data[currentIndex].desc}
					</Typography>
				}
			</div>
		</div>
	);
}

export default withTranslation()(QualitySection);
