import React from 'react';
import ParticlesJS from 'react-particles-js';
import './particles.css';

const Particles = () => {
	const [isPhone, setIsPhone] = React.useState(false);

	React.useEffect(() => {
		setIsPhone(Boolean(window.screen.width <= 450));
		window.addEventListener('resize', () => {
			setIsPhone(Boolean(window.screen.width <= 450));
		});
		return () => {
			window.removeEventListener('resize', () => {
				setIsPhone(false);
			});
		};
	}, []);

	return (
		<div>
			<ParticlesJS
				className='particles'
				params={{
					particles: {
						number: {
							value: isPhone ? 15 : 100,
						},
						size: {
							value: 3,
						},
						color: 'red',
						lineLinked: { color: '#000000' },
					},
					interactivity: {
						events: {
							onhover: {
								enable: true,
								mode: 'repulse', 
							},
						},
					},
				}}
			/>
		</div>
	);
};

export default Particles;
