import React from "react";
import { withTranslation } from "react-i18next";

import "../Global/style.css";
import "./locationSection.css";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
} from "react-simple-maps";

import Grid from "@material-ui/core/Grid";

const LocationSection = (props) => {
  const { t } = props;

  return (
    <div id="main6">
      <div className="globalOverlayHeading locationOverlayHeading">
        <p>{t("where_we_are")}</p>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={4} lg={4}>
          <div className="aboutTopHeadingMain">
            <div className="aboutTopHeading">
              <p>
                <b>{t("operating_in")}</b>
              </p>
            </div>
            <div className="aboutUsHeadingBorder" />
            <div className="aboutTopSubHeading">
              <p>{t("the_plan_of")}</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={8} lg={8}>
          <MapChart />
        </Grid>
      </Grid>
    </div>
  );
};

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const markers = [
  {
    markerOffset: -15,
    name: "Pakistan",
    coordinates: [65, 27],
    color: "green",
  },
  { markerOffset: -15, name: "France", coordinates: [3, 47], color: "red" },
  {
    markerOffset: -15,
    name: "USA",
    coordinates: [-100, 40],
    color: "blue",
  },
];

const MapChart = () => {
  return (
    <ComposableMap
      // projection="geoAzimuthalEqualArea"
      projectionConfig={{
        scale: 200,
      }}
      // width="900"
      // height="200"
      className="mapMainComponent"
      style={{ marginLeft: 0 }}
      disabled
    >
      <Geographies geography={geoUrl} disabled>
        {({ geographies }) =>
          geographies
            .filter(
              (d) =>
                d.properties.REGION_UN === "Americas" ||
                d.properties.REGION_UN === "Europe" ||
                d.properties.REGION_UN === "Asia"
            )
            .map((geo) => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                fill="#EAEAEC"
                stroke="#D6D6DA"
                style={{
                  default: { outline: "none" },
                  hover: { outline: "none" },
                  pressed: { outline: "none" },
                }}
              />
            ))
        }
      </Geographies>

      {markers.map(({ name, coordinates, markerOffset, color }) => (
        <Marker key={name} coordinates={coordinates}>
          <circle r={10} fill={color} stroke="#fff" strokeWidth={2} />
          <text
            textAnchor="middle"
            y={markerOffset}
            style={{ fontFamily: "system-ui", fill: color, fontSize: "30px" }}
          >
            {name}
          </text>
        </Marker>
      ))}
    </ComposableMap>
  );
};

export default withTranslation()(LocationSection);
