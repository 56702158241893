/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';

import './contactUs.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField, Grid, Chip, Button, Typography } from '@material-ui/core';
import globalData from '../../config/globaldata';
import { DB } from '../../config/firebase';
import { getFirebaseTimestamp } from '../../config/globalfunction';

const ContactUs = (props) => {
	const { t } = props;

	const info = [
		{
			name: t('name'),
			value: '',
			required: true,
		},
		{
			name: t('email'),
			value: '',
			required: true,
		},
		{
			name: t('phone'),
			value: '',
			required: true,
		},
		{
			name: t('company_name'),
			value: '',
			required: false,
		},
	];
	const requirement = [
		{
			seviceType: t('website_development'),
			isNeeded: false,
		},
		{
			seviceType: t('mobile_development'),
			isNeeded: false,
		},
		{
			seviceType: t('desktop_application'),
			isNeeded: false,
		},
		{
			seviceType: t('design'),
			isNeeded: false,
		},
		{
			seviceType: t('branding'),
			isNeeded: false,
		},
		{
			seviceType: t('other'),
			isNeeded: false,
		},
	];
	const amount = [
		{
			amount: '$5.00 - $10.00',
			isNeeded: false,
		},
		{
			amount: '$10.00 - $100.00',
			isNeeded: false,
		},
		{
			amount: '$100.00 - $1.000.00',
			isNeeded: false,
		},
		{
			amount: '$1.000.000 +',
			isNeeded: false,
		},
	];

	const companyInfo = {
		shortDesc: t('your_smart'),
		location: [
			<><b>Head office:</b> Suite # 01, House # 1/95 Commercial Area Block 1 Shah Faisal Colony Karachi, Pakistan</>,
			// 'USA address',
			// 'Pakistan Address',
		],
		contact: {
			email: 'example@gmail.com',
			phone: '123123123',
		},
	};
	const [userInfo, setUserInfo] = useState(info);
	const [services, setServices] = useState(requirement);
	const [budgetInfo, setBudgetInfo] = useState(amount);
	const [myBudget, setMyBudget] = useState(null);
	const [lastKey, setLastKey] = useState(null);
  const [description, setDescription] = useState('');
  const [loading, setLoading] = useState(false);

	useEffect(() => {
		// console.log("budgetInfo==>", myBudget);
		return () => {};
	});

	const handleValue = (i, e, type) => {
		switch (type) {
			case 'info':
				userInfo[i].value = e;
				setUserInfo([...userInfo]);
				break;

			case 'service':
				services[i].isNeeded = e;
				setServices([...services]);
				break;

			case 'budget':
				if (lastKey === null) {
					budgetInfo[i].isNeeded = e;

					setBudgetInfo([...budgetInfo]);
					setLastKey(i);
					setMyBudget(budgetInfo[i].amount);
				} else {
					budgetInfo[lastKey].isNeeded = !e;
					budgetInfo[i].isNeeded = e;

					budgetInfo[lastKey].isNeeded === false &&
					budgetInfo[i].isNeeded === false
						? setMyBudget(null)
						: setMyBudget(budgetInfo[i].amount);

					setBudgetInfo([...budgetInfo]);
					setLastKey(i);
				}
				break;

			default:
				break;
		}
	};

	const submitForm = async () => {
    setLoading(true)
		const userObj = {};
		userInfo.forEach((o) => (userObj[o.name] = o.value));

		const obj = {
			userInfo: userObj,
			services: services.map((o) => o.isNeeded && o.seviceType).filter(Boolean),
			myBudget,
			timestamp: getFirebaseTimestamp(),
			description,
		};

		try {
			const docRef = await DB.collection('contactForm').add({ ...obj });
			if (docRef) {
				alert('Your query is received. We will contact you soon! Thank You.');
				setBudgetInfo([...amount]);
				setUserInfo([...info]);
				setServices([...requirement]);
				setMyBudget(null);
				setLastKey(null);
				setDescription('');
			}
		} catch (error) {
			console.error('Error adding document: ', error);
		} finally {
			setLoading(false);
		}
	};

	return (
		<div id='main8'>
			<div className='contactUsMain'>
				<Grid container className='contactUsChildMain'>
					<Grid item sm={10} md={8} lg={8}>
						<div className='contactUsTopHeadingDiv'>
							<p>{t('lets_get_started')}</p>
						</div>
						<InfoComponent userInfo={userInfo} handleValue={handleValue} />
						<div className='contactUs-subHeading'>
							<p>{t('services')}</p>
						</div>
						<ServiceComponent services={services} handleValue={handleValue} />
						<div className='contactUs-subHeading'>
							<p>{t('estimated_budget')}</p>
						</div>
						<BudgetComponent
							budgetInfo={budgetInfo}
							handleValue={handleValue}
						/>
						<div className='contactUsMoreInfoDiv'>
							<TextField
								id='standard-multiline-flexible'
								label={t('we_would_like')}
								multiline
								rowsMax='4'
								fullWidth
								value={description}
								onChange={(e) => setDescription(e.target.value)}
							/>
							{loading ? (
								<CircularProgress />
							) : (
								<Button
									className='contactUsBtnSubmit'
									variant='outlined'
									color='primary'
									onClick={submitForm}
								>
									{t('submit')}
								</Button>
							)}
						</div>
					</Grid>
					<Grid item sm={12} md={4} lg={4} className='contactUsCompanyInfoMain'>
						<div>
							{/* <img src={logo} alt="cat" style={{ width: 250, height: 100 }} /> */}
							<h1>{globalData.name}</h1>
						</div>
						<div className='contactUsCompanyInfoChild'>
							<p>{companyInfo.shortDesc}</p>

							<Typography variant="h6" component="h3">{t('location')}</Typography>
							{companyInfo.location.map((item, i) => (
								<Typography key={i}>{item}</Typography>
							))}

							{/* <h3>{t('contact')}</h3> */}
							{/* <p>{companyInfo.contact.email}</p>
							<p>{companyInfo.contact.phone}</p> */}
						</div>
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

const InfoComponent = (props) => {
	const { userInfo, handleValue } = props;
	return (
		<Grid container>
			{userInfo.map(({ name, value, required }, i) => {
				return (
					<Grid key={i} item md={5} className='infoTextDivChild'>
						<TextField
							id='standard-required'
							required={required}
							fullWidth
							label={name}
							value={value}
							onChange={(e) => handleValue(i, e.target.value, 'info')}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

const ServiceComponent = (props) => {
	const { services, handleValue } = props;
	return (
		<Grid container>
			{services.map(({ seviceType, isNeeded }, i) => {
				return (
					<Grid item key={i} style={{ padding: 5 }}>
						<Chip
							className='type-chips'
							variant={isNeeded ? 'default' : 'outlined'}
							color='primary'
							label={seviceType}
							onClick={() => handleValue(i, !isNeeded, 'service')}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

const BudgetComponent = (props) => {
	const { budgetInfo, handleValue } = props;
	return (
		<Grid container>
			{budgetInfo.map(({ amount, isNeeded }, i) => {
				return (
					<Grid item key={i} style={{ padding: 5 }}>
						<Chip
							className='type-chips'
							variant={isNeeded ? 'default' : 'outlined'}
							color='primary'
							label={amount}
							onClick={() => handleValue(i, !isNeeded, 'budget')}
						/>
					</Grid>
				);
			})}
		</Grid>
	);
};

export default withTranslation()(ContactUs);
