/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import _ from "lodash";
import { withTranslation } from "react-i18next";

import "../Global/style.css";
import "./serviceSection.css";
import { scrollToElement } from "../../config/globalfunction";

import { makeStyles } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";

import desktop from "../../assets/img/desktop.svg";
import maintainance from "../../assets/img/maintainance.svg";
import mobile from "../../assets/img/mobile.svg";
import ui_ux from "../../assets/img/graphic_design.svg";
import web_development from "../../assets/img/website.svg";
import graphic_design from "../../assets/img/ui_ux.svg";

const useStyles = makeStyles({
  card: {
    // minWidth: 275
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const Myservice2 = (props) => {
  const { t } = props;

  const data = [
    {
      logo: ui_ux,
      alt: t("uiux_design"),
      service: t("uiux_design"),
      type: t("designing"),
      desc: t("beyond_pretty"),
    },
    {
      logo: mobile,
      alt: t("mobile_app_development"),
      service: t("mobile_app_development"),
      type: t("cross_platform"),
      desc: t("provide_first_class"),
    },
    {
      logo: desktop,
      alt: t("desktop_app_development"),
      service: t("desktop_app_development"),
      type: t("cross_platform"),
      desc: t("uses_modern_technology"),
    },

    {
      logo: web_development,
      alt: t("website_design"),
      service: t("website_design"),
      type: t("web"),
      sub_service: [
        "Ecommerce Solutions",
        "Progressive Web Apps (PWA)",
        "Single-/Multi-Page Apps (SPA/MPA)",
      ],
      desc: t("we_have_practical"),
    },
    {
      logo: graphic_design,
      alt: t("graphic_design"),
      service: t("graphic_design"),
      type: t("designing"),
      desc: t("give_your_design"),
    },
    {
      logo: maintainance,
      alt: t("website_and_app"),
      service: t("website_and_app"),
      type: t("maintainance"),
      desc: t("we_will_make_sure"),
    },
  ];

  return (
    <div id="main3">
      <div className="globalOverlayHeading serviceOverlayHeading">
        <p>{t("what_we_do")}</p>
      </div>
      <div className="serviceTopHeadingMain">
        <div className="serviceTopHeading">
          <p>{t("unique_hybrid")}</p>
        </div>
        <div className="serviceTopSubHeading">
          <p>{t("we_focus_on")}</p>
        </div>
      </div>
      <Grid container className="serviceMainContainer">
        {data.map((value, i) => (
          <Grid item key={i} xs={6} sm={6} md={4} lg={4}>
            <div
              className={
                "servicesCard " +
                (_.includes([1, 4], i)
                  ? "cardCenterMove"
                  : "originalServiceCard")
              }
            >
              <MyCard value={value} key={i} t={t} />
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default withTranslation()(Myservice2);

const MyCard = ({ value, t }) => {
  // console.log("cat==>", value);
  const classes = useStyles();
  return (
    <Card id="serviceRootCard">
      <CardContent className={classes.card}>
        <CardContent className="cardContentDiv">
          <img src={value.logo} alt={value.alt} className="serviceLogo" />
          <p className="serviceName">{value.service}</p>
          <p className="serviceSubHeading">{value.type}</p>
          <p className="serviceDesc">{value.desc}</p>
        </CardContent>
        <CardActions>
          <a className="serviceButton" onClick={() => scrollToElement("main8")}>
            {t("lets_talk")}
          </a>
        </CardActions>
      </CardContent>
    </Card>
  );
};
