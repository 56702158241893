let en = {
  translations: {
    intro_slogan: "Innovative Ideas Need Modern Solutions",
    who_we_are: "WHO WE ARE",
    we_develop_ideas: "We Develop Ideas, Infuse in Latent & Launch Products",
    need_solution: "need solution for your idea?",
    changing_the_world:
      "StackBeans Is Changing The World We Live In Each Stage In Turn. We Adopt On Advancement Drove Strategy To Help Envision And Reimagine The Eventual Fate Of Enterprises. Our Portfolio Traverses Key Segments Of Solidarity Including Mobile( IOS & Android), Web,Desktop (Mac,Windows,Linux) Applications",
    what_we_do: "WHAT WE DO",
    unique_hybrid: "Unique Hybrid Solutions",
    we_focus_on:
      "We Focus On Creating Innovative Digital Solutions According To The Needs Of Our Customers.",
    uiux_design: "UI/UX Design & Development",
    designing: "Designing",
    beyond_pretty:
      "Go beyond pretty. We realize that a great plan is a decent business and we make your brand extraordinary by making interactive and unique UI/UX utilizing best skills, tools and advanced approaches. turn your idea into prototype and super attractive UI/UX to increase your productivity and user experience. Give us a try",
    lets_talk: "LET'S TALK",
    mobile_app_development: "Mobile Apps Development",
    cross_platform: "Cross-Platform",
    provide_first_class:
      "StackBeans provides first-class mobile application development in the market at the best prices. We get it going by keeping the best assests, imaginative planners and undeniable group to assemble progressed and interesting mobile applications format. we develop cross-platform mobile apps for android and iOS & Windows Phone",
    desktop_app_development: "Desktop Apps Development",
    uses_modern_technology:
      "StackBeans uses modern technology to build powerful and yet small size desktop application that can run on multiple platforms from a single code-base with low memory consumption on users device. Whether you want offline or Online Desktop Application. A powerful ElectronJS developed Desktop Apps for Window, OSX, Linux",
    website_design: "Website Design & Development",
    web: "Web",
    we_have_practical:
      "We have practical experience in reactJs, javascript, jquery, HTML, CSS with PWA to create intelligent & wonderful web applications. With outwardly convincing format & current engineering to create a perfect digital asset for your business. Development & Design of custom websites that are Mobile friendly & responsive",
    graphic_design: "Graphic Design & Prototype",
    give_your_design:
      "Give your design a new feel and life. We make fully customized interfaces and graphics with the end goal that screens and web-application format will cover all parts of the structure that lift convenience, Professional Logos ,Flyers ,Banners ,Ads and Icons for your Apps and bussiness promotions 🔥🔥",
    website_and_app: "Website & App Management",
    maintainance: "Maintainance",
    we_will_make_sure:
      "We will make sure you get the best performance after the production phase and provide you excellent management of your web and apps even after the production of your app. We monitor your Web & App 24/7 so that you don't get panic when your web & app is going to be accessed by millions of users",

    ///////////////////////////////////////////////

    ///////////////////////////////////////////////

    platforms_we_work: "platforms we works on",
    ecommerce:
      "Ecommerce ,Single Page & Multi Page Apps , Progressive Web Apps (PWA)",
    android_apps:
      "Android Apps For Start-Ups And Entrepreneurs With Perfect UI & Animations",
    we_make_it:
      "We Make It Easy For You To Reach IOS World With Optimised Code And Memory",
    desktop_apps: "Desktop Apps For Your OSX With All Handy Functionalities",
    forget_the_conventional:
      "Forget The Conventional Way Of Developing Window's Desktop & Mobile Apps",
    get_your_desktop_app:
      "Get Your Desktop Apps On Linux Platform With So Ease",

    ///////////////////////////////////////////////
    // QUALITY SECTION
    ///////////////////////////////////////////////

    why_choose: "why choose",
    clean_code: "clean code",
    developers_take_good:
      "StackBeans developers take good care of the programs that they write. We make it simple and orderly for future development of your project.We do this by following programming conventions, documenting, writing code in an easy-to-read format with understandable variable and function names.In this age where many people browse the web using small devices, StackBeans must make sure that websites can be viewed on virtually any size screen, so we build in all the additional support necessary to support anything from mobile phones to large screens.",
    agile_approach: "agile approach",
    plan_for_how:
      "StackBeans plan for how we can make things better for the people who use your digital content. Our Vision takes the form of roadmap. Our approach is principles-based way of working that allows designers, developers, editors and subject experts to truly collaborate in multidisciplinary teams. DESIGN THINKING is our belief, LEAN is our faith and AGILE is our Trust.",
    deep_code: "deep code analysis",
    make_sure_that:
      "StackBeans make sure that your code is bug free and rust free. Each phase of development pass through series of test to make it sure that non of the application layer is missed. Every missed vulnerability increases the risk of large system-wide failures, decreases security, and takes away from an IT budget. StackBeans  makes sure that Application developed in our organization don't result in security issues, compliance failure or any other issue.",
    scalability: "scalability",
    in_order_to_ensure:
      "In order to ensure that your software will be usable no matter how many customers you have, we writes code in such a way as to ensure the maximum scalability. Everything will be written with the future in mind, unless you ask otherwise. This may cost more short-term but it costs far less long-term because non-scaling software must be revised or replaced entirely to keep up with increasing demand and changes to the architecture.",
    robust_testing: "robust testing",
    during_each_phase:
      "During each phase of the development of your software, we run tests to identify flaws of all kinds to make sure that we give you the optimal results you desire and deserve. At the end of development, further testing is done and, if you need it, user testing can be performed before releasing the software for general end-users. Why worry about the chance of a crash when you can avoid it?",
    code_appraisal: "code appraisal",
    break_down_your_app:
      "StackBeans break down your application into small encapsulated parts which can then be composed to make more complex, robust UI and apply bussiness logic to every single component so that it become painless for our developers and team leads in evaluating the requirements.",

    ///////////////////////////////////////////////
    // LOCATION SECTION
    ///////////////////////////////////////////////

    where_we_are: "where we are",
    operating_in: "Operating In 3 Different Regions And Cultures",
    the_plan_of:
      "the plan of having work team thousands of miles away might be engaging, what about when We are trying to run a business with them? we believe in providing our services not only with-in the local state boundaries but to international clients also",

    ///////////////////////////////////////////////
    // CONTACTUS SECTION
    ///////////////////////////////////////////////

    name: "name",
    email: "email",
    phone: "phone",
    company_name: "company name",
    website_development: "website development",
    mobile_development: "mobile development",
    desktop_application: "desktop application",
    design: "design",
    branding: "branding",
    other: "other",
    your_smart:
      "your smart place to convert your ideas into working state. It can be anything: A website, A mobile Application (IOS & Android), A Desktop Application (OSX,Windows,Linux). Your idea is our goal to achieve it 😊",
    lets_get_started: "let's get started",
    services: "services",
    estimated_budget: "Estimated Budget",
    submit: "submit",
    location: "Location",
    contact: "Contact",
    we_would_like: "We Would Like To Know More",

    all_rights: "All Rights Reserved",
  },
};

export default en;
